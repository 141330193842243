"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
class Ungpd {
    constructor(form) {
        Object.defineProperty(this, "form", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "notices", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "successTemplate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "errorTemplate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "consent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "email", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.form = form;
        this.id = form.getAttribute('id') || '';
        this.email = this.form.querySelector('input[name="email"]');
        this.consent = this.form.querySelector('input[name="user_consent"]');
        this.successTemplate = document.querySelector(`template[id="${this.id}-success"]`);
        this.errorTemplate = document.querySelector(`template[id="${this.id}-error"]`);
        this.setupEventListener();
    }
    handleSuccess() {
        var _a, _b;
        const successElement = (_a = this.successTemplate) === null || _a === void 0 ? void 0 : _a.content.cloneNode(true);
        if ((_b = this.successTemplate) === null || _b === void 0 ? void 0 : _b.parentNode) {
            this.notices.push(this.successTemplate.parentNode.appendChild(successElement));
        }
    }
    handleError(message) {
        var _a, _b;
        const errorElement = (_a = this.errorTemplate) === null || _a === void 0 ? void 0 : _a.content.cloneNode(true);
        const messageElement = errorElement === null || errorElement === void 0 ? void 0 : errorElement.querySelector('.message');
        if (messageElement) {
            messageElement.innerHTML = message.toString();
        }
        if ((_b = this.errorTemplate) === null || _b === void 0 ? void 0 : _b.parentNode) {
            this.notices.push(this.errorTemplate.parentNode.appendChild(errorElement));
        }
    }
    clearNotices() {
        this.notices.forEach(notice => { var _a; return (_a = notice.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(notice); });
    }
    setupEventListener() {
        this.form.addEventListener("submit", this.handleFormSubmit.bind(this));
    }
    handleFormSubmit(event) {
        return __awaiter(this, void 0, void 0, function* () {
            event.preventDefault();
            this.clearNotices();
            const subscription = this.subscriptionData();
            try {
                yield this.submitSubscription(subscription);
                this.handleSuccess();
            }
            catch (error) {
                this.handleError(error.message);
            }
        });
    }
    subscriptionData() {
        const listIds = this.form.getAttribute('data-js-ungpd-list-ids');
        const doubleOptInIssueId = this.form.getAttribute('data-js-ungpd-double-opt-in-issue-id');
        const confirmationIssueId = this.form.getAttribute('data-js-ungpd-confirmation-issue-id');
        const subscriptionConfirmedUrl = this.form.getAttribute('data-js-ungpd-subscription-confirmed-url');
        const subscriptionFailedUrl = this.form.getAttribute('data-js-ungpd-subscription-failed-url');
        const lists = listIds ? listIds.split(",").map((listId) => listId.trim()) : [];
        const subscription = {
            Contact: { Email: this.email.value },
            ConsentText: this.consent.value,
            ListIds: lists
        };
        if (doubleOptInIssueId)
            subscription.DoubleOptIn = { Issue: { IssueId: doubleOptInIssueId } };
        if (confirmationIssueId)
            subscription.ConfirmationIssue = { IssueId: confirmationIssueId };
        if (subscriptionConfirmedUrl)
            subscription.SubscriptionConfirmedUrl = subscriptionConfirmedUrl;
        if (subscriptionFailedUrl)
            subscription.SubscriptionFailedUrl = subscriptionFailedUrl;
        return subscription;
    }
    submitSubscription(subscription) {
        return __awaiter(this, void 0, void 0, function* () {
            const accountId = this.form.getAttribute('data-js-ungpd-id');
            const response = yield fetch("https://ui.ungpd.com/Api/Subscriptions/" + accountId, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(subscription)
            });
            if (!response.ok) {
                throw new Error(`Failed to subscribe: ${response.status}`);
            }
            this.consent.checked = false;
            this.email.value = "";
        });
    }
}
document.addEventListener("DOMContentLoaded", () => {
    const ungpdForms = [...document.querySelectorAll("[data-js-ungpd-id]")];
    ungpdForms.forEach(form => {
        new Ungpd(form);
    });
});
